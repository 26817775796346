<div class="container">
  <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapseOne">
          <h6>Links to Protein Data Bank</h6>
        </a>
      </div>
      <div id="collapseOne" class="collapse" data-parent="#accordion">
        <div class="container row row-content">
          <img
            class="d-block img-fluid"
            src="../../assets/structures/rcsb.png"
            style="height: 50px; width: 100px"
          />

          <div class="col-sm-4">
            <ul>
              <li>
                <a href="https://www.rcsb.org/structure/1M1N">
                  Nitrogenase MoFe protein from Azotobacter vinelandii
                </a>
              </li>
              <li>
                <a href="https://www.rcsb.org/structure/5N6Y">
                  Azotobacter vinelandii vanadium nitrogenase
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
