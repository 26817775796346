<nav>
  <ul>
    <li><a [routerLink]="['/homepage']">HOME</a></li>
    <li><a [routerLink]="['/guide']">GUIDE</a></li>
    <li><a [routerLink]="['/NFixDB']">NFixDB</a></li>
    <li><a [routerLink]="['/software']">SOFTWARE</a></li>
    <li><a [routerLink]="['/gallery']">GALLERY</a></li>
    <li><a [routerLink]="['/structures']">STRUCTURES</a></li>
    <li><a [routerLink]="['/']">PUBLICATIONS</a></li>
    <li><a [routerLink]="['/']">LINKS</a></li>
    <li><a [routerLink]="['/people']">TEAM</a></li>
    <li><a [routerLink]="['/location']">LOCATIONS</a></li>
  </ul>
</nav>
