import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule,HttpClient } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { environment } from '../environments/environment';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HeroComponent } from './hero/hero.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';


import { P404Component } from './p404/p404.component';


// import {AuthguardGuard} from './authguard.guard';
import { SoftwareComponent } from './software/software.component';
import { LinksComponent } from './links/links.component';
import { FungenesComponent } from './fungenes/fungenes.component';
import { NitroFixDBComponent } from './nitro-fix-db/nitro-fix-db.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PeopleComponent } from './people/people.component';
import { CollaboratorsComponent } from './collaborators/collaborators.component';
import { LocationComponent } from './location/location.component';
import { GuideComponent } from './guide/guide.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeroComponent,
    FooterComponent,
    HomepageComponent,
    P404Component,
    SoftwareComponent,
    LinksComponent,
    FungenesComponent,
    NitroFixDBComponent,
    GalleryComponent,
    PeopleComponent,
    CollaboratorsComponent,
    LocationComponent,
    GuideComponent,  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
