import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pb-nitro-fix-db',
  templateUrl: './nitro-fix-db.component.html',
  styleUrls: ['./nitro-fix-db.component.scss']
})
export class NitroFixDBComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
