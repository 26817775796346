<div class="container center">
  <div class="row row-content">
    <div class="col">
      <div id="mycarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner img-fluid" role="listbox">
          <div class="carousel-item active">
            <a href="https://github.com/raw-lab/nitrogenase"
              ><img
                class="rounded mx-auto d-block"
                src="../../assets/home/NFixDB.jpg"
            /></a>
          </div>
          <div class="carousel-item">
            <img
              class="rounded mx-auto d-block"
              src="../../assets/home/home.png"
            />
          </div>
        </div>
        <ol class="carousel-indicators">
          <li data-target="#mycarousel" data-slide-to="0" class="active"></li>
          <li data-target="#mycarousel" data-slide-to="1"></li>
        </ol>
        <a
          class="carousel-control-prev"
          href="#mycarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a
          class="carousel-control-next"
          href="#mycarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
    </div>
  </div>
  <br />
  <!-- <h4>mission statement</h4>
  <h4>news</h4>
  <h4>twitter</h4> -->
  <!-- <div class="col-sm-5"> -->
  <!-- <h3> Project Spotlight</h3> -->
  <!-- <div class="panel panel-danger">
      <div class="panel-heading">
        <h3 class="panel-title" style="font-size: 20px">
          <i class="fa fa-twitter-square" aria-hidden="true"></i>
          Nitrogenase
        </h3>
      </div> -->
  <!-- <div class="panel-body" style="font-size: 20px">
        <a
          class="twitter-timeline"
          data-width="100%"
          data-height="300"
          href="https://twitter.com/"
          style="font-size: 20px"
          >Tweets</a
        >
      </div>
      <script
        async
        src="//platform.twitter.com/widgets.js"
        charset="utf-8"
      ></script> -->
  <div class="row row-content" style="padding-top: 10px">
    <div class="col-sm-1">
      <!-- <h3> Project Spotlight</h3> -->
    </div>
    <!-- <div class="col-sm-3" style="padding-left: 80px">
      <div class="panel panel-danger">
        <div class="panel-heading">
          <h4 class="panel-title">
            <i class="fa fa-twitter-square" aria-hidden="true"></i>
            Raw Lab
          </h4>
        </div>
        <div class="panel-body" style="font-size: 20px">
          <a class="twitter-timeline" href="https://twitter.com/lab_raw" data-width="100%" data-height="400"
                 data-border-color="#a80000"  style="font-size: 20px;">Tweets by @lab_raw</a>
          <a
            class="twitter-timeline"
            href="https://twitter.com/lab_raw?ref_src=twsrc%5Etfw"
            data-width="100%"
            data-height="300"
            ><h6>Tweets by lab_raw</h6></a
          >
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </div>
      </div>
    </div> -->

    <!-- <div
          class="col-sm-3"
          style="font-family: Roboto; font-size: 18px; padding-left: 50px"
        >
          <p></p>

          <h3 style="font-size: 30px"><i>Contact</i></h3>
          <div>
            <a
              href="mailto:rwhit101@uncc.edu"
              style="text-decoration: underline"
              >Richard Allen White III
            </a>
            <p>Assistant professor</p>
            <a
              href="https://cci.uncc.edu/bioinformatics/47/3"
              style="text-decoration: underline"
              >Bioinformatics and Genomics</a
            >
            <a href="https://www.uncc.edu/" style="text-decoration: underline"
              >University of North Carolina at Charlotte</a
            >
            <p>Phone:<a href="tel:704-687-8792">704-687-8792</a></p>
            <p>
              <a [routerLink]="['/address']" style="text-decoration: underline"
                >Address and Directions</a
              >
            </p>
          </div>
        </div> -->
  </div>
  <!-- </div> -->
  <!-- </div> -->

  <div class="row">
    <div class="panel panel-danger">
      <div class="panel-heading">
        <h4 class="panel-title">
          <i class="fa fa-twitter-square" aria-hidden="true"></i>
          Nitrogenase
        </h4>
      </div>
      <div class="panel-body" style="font-size: 20px">
        <!-- <a class="twitter-timeline" href="https://twitter.com/lab_raw" data-width="100%" data-height="400"
               data-border-color="#a80000"  style="font-size: 20px;">Tweets by @lab_raw</a> -->
        <a
          class="twitter-timeline"
          href="https://twitter.com/Nitrogenase3"
          data-width="100%"
          data-height="300"
          ><h6>Tweets</h6></a
        >
        <!-- <script
          async
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script> -->
      </div>
    </div>
    <div class="col-sm-4">
      <h4>Nitrogenase statement</h4>
      <!-- <p>
        Comprehensive database for diazotroph nitrogenases, alternative
        nitrogenases, and nitrogenase-like enzymes at the University of North
        Carolina at Charlotte (UNCC)
      </p> -->
    </div>

    <div class="col-sm-4">
      <h4>NEWS</h4>
    </div>
  </div>
</div>
