<div class="container">
  <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapseOne">
          <h6>DOMESTIC SITES</h6>
        </a>
      </div>
      <div id="collapseOne" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row row-content">
            <div class="col-sm">
              <h5>R. J. Cook Agronomy Farm</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d87484.61394016695!2d-117.08500808123587!3d46.74726853489101!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x52424039ad17b75e!2sCook%20Research%20Farm-%20Cook%20East!5e0!3m2!1sen!2sus!4v1619039137276!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <div class="col-sm">
              <h5>Kambitsch Farm</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21879.28184269258!2d-116.99732186316244!3d46.727232091450254!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ad5c80d95b74f!2sParker%20Farm!5e0!3m2!1sen!2sus!4v1619039522366!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <!--<div class="col-sm">
              <h5>Shark Bay</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918785.4100193644!2d113.07772176928191!3d-25.902189446733157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2be0d84888c6260d%3A0xdbf8810aea86d204!2sShark%20Bay!5e0!3m2!1sen!2sus!4v1618508827102!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>-->
          </div>

          <div class="row row-content">
            <!-- <div class="col-sm">
              <h5>Great Salt Lake</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d768741.485452023!2d-113.04036249551895!3d41.180426182888766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875335b1dff77dc7%3A0xe4f87e4c6ef796fb!2sGreat%20Salt%20Lake!5e0!3m2!1sen!2sus!4v1618509020886!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div> -->

            <div class="col-sm">
              <h5>Lux Arbor</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11768.465404047096!2d-85.4640563!3d42.489079!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9917545f6edfef7!2sLux%20Arbor%20Reserve!5e0!3m2!1sen!2sus!4v1618509517478!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <div class="col-sm">
              <h5>Kellogg Biological Station</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11784.11870164328!2d-85.4022846!3d42.4058131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e86ae20e3a62103!2sKellogg%20Biological%20Station--Michigan%20State%20University!5e0!3m2!1sen!2sus!4v1618948217319!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapsetwo">
          <h6>INTERNATIONAL SITES</h6>
        </a>
      </div>

      <div id="collapsetwo" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <!-- <div class="row row-content">
            <div class="col-sm">
              <h5>Kelly lake</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18188.394798121764!2d-120.05041045827532!3d55.26108319716889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53904bd29d44a245%3A0x8dfb84fbcc71eb58!2sKelly%20Lake%2C%20BC%20V0C%202L0%2C%20Canada!5e0!3m2!1sen!2sus!4v1618508461411!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <div class="col-sm">
              <h5>Pavilion Lake</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40288.75633563029!2d-121.77007295377297!3d50.8673246108516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5480701f716adb5b%3A0x9b8afb4975b6c8dc!2sPavilion%20Lake!5e0!3m2!1sen!2sus!4v1618508728917!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <div class="col-sm">
              <h5>Shark Bay</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d918785.4100193644!2d113.07772176928191!3d-25.902189446733157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2be0d84888c6260d%3A0xdbf8810aea86d204!2sShark%20Bay!5e0!3m2!1sen!2sus!4v1618508827102!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>
          </div> -->

          <div class="row row-content">
            <!-- <div class="col-sm">
              <h5>Great Salt Lake</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d768741.485452023!2d-113.04036249551895!3d41.180426182888766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875335b1dff77dc7%3A0xe4f87e4c6ef796fb!2sGreat%20Salt%20Lake!5e0!3m2!1sen!2sus!4v1618509020886!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div> -->

            <!-- <div class="col-sm">
              <h5>Greenlake</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5830.89900030709!2d-75.97068107297328!3d43.053012866585625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d98e417e021e01%3A0x27a1c73930e9dc45!2sGreen%20Lake!5e0!3m2!1sen!2sus!4v1618509112267!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div> -->

            <div class="col-sm">
              <h5>Shaanxi Province, China</h5>
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px; width: 250"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3296.635994954696!2d108.0666667!3d34.2833333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDE3JzAwLjAiTiAxMDjCsDA0JzAwLjAiRQ!5e0!3m2!1sen!2sus!4v1618948488455!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
              <br />
              <br />
            </div>

            <div class="col-sm">
              <div
                id="map-container-google-1"
                class="z-depth-1-half map-container"
                style="height: 500px"
              >
                <!-- <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11784.11870164328!2d-85.4022846!3d42.4058131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e86ae20e3a62103!2sKellogg%20Biological%20Station--Michigan%20State%20University!5e0!3m2!1sen!2sus!4v1618948217319!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe> -->
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
