import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';

// interface BudgetSchema{
//   id: string;
//   budget: number;
//   maxbudget: number;
//   color: string;
// }

@Component({
  selector: 'pb-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {

  // budgetCollection : AngularFirestoreCollection<BudgetSchema>;
  // budgetData: Observable<BudgetSchema[]>;


}
