<footer>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-auto">
        <b> All rights reserved &copy; Richard Allen White III </b>
      </div>
      <div>
        <ul class="list-unstyled list-inline text-center">
          <li class="list-inline-item">
            <a href="mailto:rwhit101@uncc.edu"
              ><img
                class="d-block img-fluid"
                src="../../assets/gmail.png"
                style="height: 25px; width: 30px"
            /></a>
          </li>
          <li class="list-inline-item">
            <a
              href="https://scholar.google.com/citations?user=FKURoi8AAAAJ&hl=en"
              ><img
                class="d-block img-fluid"
                src="../../assets/google.png"
                style="height: 25px; width: 30px"
            /></a>
          </li>
          <li class="list-inline-item">
            <a
              href="https://www.linkedin.com/in/richard-allen-white-iii-a1823b2b/"
            >
              <img
                class="d-block img-fluid"
                src="../../assets/linkedin.png"
                style="height: 25px; width: 30px"
              />
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://github.com/raw-lab/NFixDB"
              ><img
                class="d-block img-fluid"
                src="../../assets/GitHub.png"
                style="height: 25px; width: 30px"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
