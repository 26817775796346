<div class="container">
  <div class="row row-content">
    <div class="col">
      <div id="mycarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_1.jpg"
              style="width: 100%; height: 50%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_2.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_3.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_4.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_5.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_6.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_7.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_8.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_9.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_10.jpg"
              style="width: 100%; height: 25%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_11.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_12.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_13.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_14.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <!-- <div class="carousel-item center">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_15.jpg"
              style="width: 50%; height: 45%"
            />
          </div> -->
          <!-- <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_16.jpg"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/farm_17.jpg"
              style="width: 100%; height: 45%"
            />
          </div> -->
        </div>
        <ol class="carousel-indicators">
          <li data-target="#mycarousel" data-slide-to="0" class="active"></li>
          <li data-target="#mycarousel" data-slide-to="1"></li>
          <li data-target="#mycarousel" data-slide-to="2"></li>
          <li data-target="#mycarousel" data-slide-to="3"></li>
          <li data-target="#mycarousel" data-slide-to="4"></li>
          <li data-target="#mycarousel" data-slide-to="5"></li>
          <li data-target="#mycarousel" data-slide-to="6"></li>
          <li data-target="#mycarousel" data-slide-to="7"></li>
          <li data-target="#mycarousel" data-slide-to="8"></li>
          <li data-target="#mycarousel" data-slide-to="9"></li>
          <li data-target="#mycarousel" data-slide-to="10"></li>
          <li data-target="#mycarousel" data-slide-to="11"></li>
          <li data-target="#mycarousel" data-slide-to="12"></li>
          <li data-target="#mycarousel" data-slide-to="13"></li>
        </ol>
        <a
          class="carousel-control-prev"
          href="#mycarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a
          class="carousel-control-next"
          href="#mycarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
    </div>
  </div>
</div>
