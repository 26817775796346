<div class="container">
  <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapseOne">
          <h6>DOMESTIC</h6>
        </a>
      </div>
      <div id="collapseOne" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row row-content">
            <a class="card-link" data-toggle="collapse" href="#collapseUNCC"
              ><img
                class="d-block img-fluid"
                src="../../assets/University/UNCC.png"
                style="height: 150px; width: 300px"
            /></a>

            <a class="card-link" data-toggle="collapse" href="#collapseWSU"
              ><img
                class="d-block img-fluid"
                src="../../assets/University/WSU.png"
                style="height: 150px; width: 300px"
            /></a>

            <a class="card-link" data-toggle="collapse" href="#collapseMSU"
              ><img
                class="d-block img-fluid"
                src="../../assets/University/MSU.png"
                style="height: 130px; width: 250px"
            /></a>
          </div>
        </div>

        <div id="collapseUNCC" class="collapse" data-parent="#collapseOne">
          <div class="card-body">
            <div class="row row-content">
              <div class="col-sm-4">
                <div class="img-fluid">
                  <img
                    src="../../assets/Contributors/Richard.jpeg"
                    style="width: 100%; height: 410px"
                  />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="card">
                  <div class="container" style="height: 410px">
                    <h2>Richard Allen White III</h2>
                    <div class="title">
                      Assistant Professor - University Of North Carolina at
                      Charlotte
                    </div>
                    <br />
                    <div>
                      Postdoctoral Computational biology - Pacific Northwest
                      National Lab (PNNL)
                      <br />
                      Ph.D. Microbiology and Immunology - University of British
                      Columbia
                      <br />
                      M.S. Biology (Molecular Virology) - California State
                      University - East Bay (formally Hayward)
                      <br />
                      B.S. Cellular and Molecular Biology - California State
                      University - East Bay (formally Hayward)
                    </div>

                    <div>
                      <!-- <h6>
                      <a href="mailto:rwhit101@uncc.edu"> rwhit101@uncc.edu </a>
                    </h6> -->
                      <a href="mailto:rwhit101@uncc.edu"
                        ><img
                          class="d-block img-fluid"
                          src="../../assets/gmail.png"
                          style="height: 35px; width: 45px"
                      /></a>
                    </div>
                    <h6>Specialties:</h6>
                    <ul>
                      <li>
                        Multiomics (Next Generation Sequencing/Mass
                        Spectrometry)
                      </li>
                      <li>Virology</li>
                      <li>
                        De novo assembly (complex metagenomes/large genomes)
                      </li>
                      <li>Novel antivirals and antibiotics</li>
                      <li>Bacteriophage therapy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="row row-content container">
              <div class="col-sm-4">
                <div class="img-fluid">
                  <img
                    src="../../assets/Contributors/Rakesh.jpeg"
                    style="width: 100%; height: 400px"
                  />
                </div>
              </div>
              <div class="col-sm-8">
                <div class="card" style="width: 100%; height: 400px">
                  <div class="container">
                    <div></div>
                    <h2>Rakesh Bommisetty</h2>
                    <div class="title">
                      Graduate Research Assistant - University Of North Carolina
                      at Charlotte
                    </div>
                    <br />
                    <div>
                      M.S Computer Science (University of North Carolina at
                      Charlotte) <br />
                      B.Tech Computer Science Engineering (S.R.M University)
                    </div>
                    <div>
                      <!-- <h6>
                      <a href="mailto: rbommise@uncc.edu">
                        rbommise@uncc.edu
                      </a>
                    </h6> -->
                      <a href="mailto:rbommise@uncc.edu"
                        ><img
                          class="d-block img-fluid"
                          src="../../assets/gmail.png"
                          style="height: 35px; width: 45px"
                      /></a>
                    </div>

                    <div>
                      Professional Experience: Worked as a Java Full Stack
                      Developer for 2 years in Cognizant Technology Solutions.
                    </div>

                    <h6>Specialities:</h6>
                    <div style="text-align: justify">
                      <ul>
                        <li>
                          Languages: Java, Python, JavaScript, C, C++, Node.js,
                          HTML5, CSS, SQL
                        </li>
                        <li>Database: MySQL, Oracle SQL, NoSQL- MongoDB</li>
                        <li>
                          Technologies & Tools: Spring MVC, Spring Web Flow,
                          JSON, XML, Git, JIRA, Express, PUG, Handlebars, EJS,
                          Tableau
                        </li>
                      </ul>
                    </div>

                    <!-- <div><button class="button">Contact</button></div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="collapseWSU" class="collapse" data-parent="#collapseOne">
          <div class="row row-content container">
            <div class="col-sm-4">
              <div class="img-fluid">
                <img
                  src="../../assets/Contributors/Maren.jpg"
                  style="width: 100%; height: 410px"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card">
                <div class="container" style="height: 410px">
                  <h2>Maren L. Friesen</h2>
                  <div class="title">
                    Assistant Professor - Washington State University
                  </div>
                  <br />
                  <div>
                    Ph.D. Population Biology, 2008, University of California,
                    Davis<br />

                    B.S. Ecology and Mathematics, 2003, University of British
                    Columbia, Canada <br />
                    M.S. Biology (Molecular Virology) - California State
                    University
                  </div>

                  <div>
                    <!-- <h6>
                      <a href="mailto:m.friesen@wsu.edu"> m.friesen@wsu.edu </a>
                    </h6> -->
                    <a href="mailto:m.friesen@wsu.edu"
                      ><img
                        class="d-block img-fluid"
                        src="../../assets/gmail.png"
                        style="height: 35px; width: 45px"
                    /></a>
                  </div>
                  <h6>Research Specialty and Interests</h6>
                  <div>
                    I am fascinated by beneficial plant-microbe interactions,
                    particularly those between nitrogen-fixing bacteria and
                    plants. Much of my work is in the legume-rhizobia system,
                    utilizing natural genetic variation in both partners to ask
                    questions about adaptation and conflict. I am also working
                    in grass-associative nitrogen fixation systems to understand
                    the functioning of interrelationships between plants and
                    their rhizosphere communities and the evolutionary stability
                    and molecular basis of these interactions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="collapseMSU" class="collapse" data-parent="#collapseOne">
          <div class="row row-content container">
            <div class="col-sm-4">
              <div class="img-fluid">
                <img
                  src="../../assets/Contributors/Tiemann.png"
                  style="width: 100%; height: 400px"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card">
                <div class="container" style="height: 400px">
                  <div></div>
                  <h2>Lisa Tiemann</h2>
                  <div class="title">
                    Assistant Professor - Michigan State University
                  </div>

                  <div>
                    Postdoc. University of New Hampshire<br />
                    PhD University of Kansas<br />
                    B.M. University of Wyoming
                  </div>
                  <div>
                    <!-- <h6>
                      <a href="mailto:m.friesen@wsu.edu"> m.friesen@wsu.edu </a>
                    </h6> -->
                    <a href="mailto:ltiemann@msu.edu"
                      ><img
                        class="d-block img-fluid"
                        src="../../assets/gmail.png"
                        style="height: 35px; width: 45px"
                    /></a>
                  </div>
                  <div>
                    <h6>
                      <a href="https://tiemann.psm.msu.edu/">
                        Dr. Tiemann's Website
                      </a>
                    </h6>
                  </div>

                  <h6>Area of Expertise:</h6>
                  <p>
                    Soil ecology and biogeochemistry; mechanisms of soil organic
                    matter formation and microbial controls on soil nitrogen
                    cycling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapseTwo">
          <h6>INTERNATIONAL</h6>
        </a>
      </div>

      <div id="collapseTwo" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="row row-content container">
            <div class="col-sm-4">
              <div class="img-fluid">
                <img
                  src="../../assets/Contributors/mingming.jpg"
                  style="width: 100%; height: 405px"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card">
                <div class="container" style="height: 405px">
                  <div></div>
                  <h2>Mingming Yang</h2>
                  <div class="title">
                    Lecturer, Department of Agronomy, Northwest A&F University,
                    Shaanxi
                  </div>
                  <p>
                    Ph.D. (Plant Pathology), Department of Plant pathology,
                    Nanjing Agricul- tural University, China, 2011.
                  </p>

                  <div>
                    <!-- <h6>
                      <a href="mailto:myang@nwsuaf.edu.cn.">
                        myang@nwsuaf.edu.cn.
                      </a>
                    </h6> -->
                    <a href="mailto:myang@nwsuaf.edu.cn."
                      ><img
                        class="d-block img-fluid"
                        src="../../assets/gmail.png"
                        style="height: 35px; width: 45px"
                    /></a>
                  </div>

                  <div>
                    　<b>Professional Experience:</b><br />　Visiting schorlar,
                    Department of Plant Pathology, Washington State Univer-
                    sity, Pullman, WA, 2009-2011.
                  </div>
                  <h6>Research Interests</h6>
                  <div style="text-align: justify">
                    <ul>
                      <li>
                        Biological control of plant disease, plant-microb
                        interaction
                      </li>
                      <li>
                        To identify genes which affect wheat grain development
                        and improve the grain weight
                      </li>
                      <li>Wheat breeding</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-content container">
            <div class="col-sm-4">
              <div class="img-fluid">
                <img
                  src="../../assets/Contributors/Jiangang Li.jpg"
                  style="width: 100%; height: 400px"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card">
                <div class="container" style="height: 400px">
                  <div></div>
                  <h2>Jiangang Li</h2>
                  <div class="title"></div>

                  <p>
                    Institute of Soil Science, Chinese Academy of Sciences,
                    Nanjing, China
                  </p>
                  <div>
                    <a href="mailto:jgli@issas.ac.cn"
                      ><img
                        class="d-block img-fluid"
                        src="../../assets/gmail.png"
                        style="height: 35px; width: 45px"
                    /></a>
                  </div>
                  <h6>Skills and Expertise:</h6>
                  <ul>
                    <li>Agricultural Microbiology</li>
                    <li>Fertilization</li>
                    <li>Soil</li>
                    <li>Plasma Gases</li>
                    <li>Cold Plasma</li>
                    <li>Plasma Treatment</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
