<div class="container">
  <div id="accordion">
    <div class="card">
      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapseOne">
          <h6>FunGene</h6>
        </a>
      </div>
      <div id="collapseOne" class="collapse" data-parent="#accordion">
        <div class="container row row-content">
          <img
            class="d-block img-fluid"
            src="../../assets/NFixDB/FunGene.png"
            style="height: 50px; width: 100px"
          />

          <div class="col-sm-4">
            <ul>
              <li>
                <a href="http://fungene.cme.msu.edu/hmm_detail.spr?hmm_id=328">
                  nifH
                </a>
              </li>
              <li>
                <a href="http://fungene.cme.msu.edu/hmm_detail.spr?hmm_id=320">
                  nifD
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapsetwo">
          <h6>MetaCyc Pathway</h6>
        </a>
      </div>

      <div id="collapsetwo" class="collapse" data-parent="#accordion">
        <div class="container row row-content">
          <img
            class="d-block img-fluid"
            src="../../assets/NFixDB/MetaCyc.PNG"
            style="height: 50px; width: 100px"
          />
          <ul>
            <li>
              <a
                href="https://biocyc.org/META/NEW-IMAGE?type=PATHWAY&object=N2FIX-PWY"
              >
                Nitrogen fixation I (ferredoxin)
              </a>
            </li>
            <li>
              <a
                href="https://biocyc.org/META/NEW-IMAGE?type=PATHWAY&object=PWY-7576"
              >
                Nitrogen fixation II (ferredoxin)
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="card-header">
        <a class="card-link" data-toggle="collapse" href="#collapsethree">
          <h6>KEGG</h6>
        </a>
      </div>

      <div id="collapsethree" class="collapse" data-parent="#accordion">
        <div class="container row row-content">
          <div>
            <img
              class="d-block img-fluid"
              src="../../assets/NFixDB/KFGG.PNG"
              style="height: 50px; width: 100px; text-align: center"
            />
          </div>
          <div class="col-sm-4">
            <ul>
              <li>
                <a href="https://www.genome.jp/kegg-bin/show_pathway?map00910">
                  Nitrogen metabolism
                </a>
              </li>
              <li>
                <a href="https://www.genome.jp/dbget-bin/www_bget?K02588">
                  ORTHOLOGY: K02588
                </a>
              </li>

              <li>
                <a href="https://www.genome.jp/dbget-bin/www_bget?K02586">
                  ORTHOLOGY: K02586
                </a>
              </li>
              <li>
                <a
                  href="https://www.genome.jp/dbget-bin/www_bget?K22896+K22897+K22898+K22899"
                >
                  ORTHOLOGY: K22896
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
