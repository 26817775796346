import { Component, OnInit } from '@angular/core';
import {GlobalConstants} from '../app.global';
import { Router } from '@angular/router';

@Component({
  selector: 'pb-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {


}
