<div class="container">
  <div class="row row-content">
    <div class="col-sm">
      <a href="https://github.com/raw-lab/NFixDB"
        ><img
          class="rounded mx-auto d-block"
          src="../../assets/home/NFixDB.jpg"
          style="max-width: 100%; height: 35%"
      /></a>
    </div>
    <div class="col-sm">
      <img
        src="../../assets/Software/github_overview.png"
        style="max-width: 100%; height: 35%"
      />
      <button type="button" class="btn btn-primary" id="liveToastBtn">
        Show live toast
      </button>

      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 5">
        <div
          id="liveToast"
          class="toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-header">
            <img src="..." class="rounded me-2" alt="..." />
            <strong class="me-auto">GitHub Link</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">
            <a href="https://github.com/raw-lab/NFixDB">Repository Link</a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col"> -->
    <!-- <div id="mycarousel" class="img-fluid" data-ride="carousel"> -->
    <!-- <div class="img-fluid" role="listbox"> -->
    <!-- <div class="img-fluid">
          <a href="https://github.com/raw-lab/nitrogenase"
            ><img
              class="rounded mx-auto d-block"
              src="../../assets/home/NFixDB.jpg"
              style="max-width: 100%; height: 35%"
          /></a> -->
    <!-- <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="popover"
              data-placement="top"
              data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
            >
              Popover on top
            </button> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
</div>
