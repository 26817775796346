<div class="container">
  <div class="row row-content">
    <div class="col">
      <div id="mycarousel" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div class="carousel-item active">
            <img
              class="d-block img-fluid"
              src="../../assets/Guide/F1.PNG"
              style="width: 100%; height: 30%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/Guide/F2.PNG"
              style="width: 100%; height: 45%"
            />
          </div>
          <div class="carousel-item">
            <img
              class="d-block img-fluid"
              src="../../assets/Guide/F3.PNG"
              style="width: 100%; height: 45%"
            />
          </div>
        </div>
        <ol class="carousel-indicators">
          <li data-target="#mycarousel" data-slide-to="0" class="active"></li>
          <li data-target="#mycarousel" data-slide-to="1"></li>
          <li data-target="#mycarousel" data-slide-to="2"></li>
        </ol>
        <a
          class="carousel-control-prev"
          href="#mycarousel"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a
          class="carousel-control-next"
          href="#mycarousel"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
    </div>
  </div>
</div>
