import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { P404Component } from './p404/p404.component';
import { SoftwareComponent } from './software/software.component';
import { LinksComponent } from './links/links.component';
import { FungenesComponent } from './fungenes/fungenes.component';
import { NitroFixDBComponent } from './nitro-fix-db/nitro-fix-db.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PeopleComponent } from './people/people.component';
import { LocationComponent } from './location/location.component';
import { GuideComponent } from './guide/guide.component';

const routes: Routes = [
  {
    path : 'homepage',
    component: HomepageComponent,
    pathMatch: 'full'
  },
  {
    path : 'gallery',
    component: GalleryComponent,
    pathMatch: 'full'
  },
  {
    path: 'NitroFixDB',
    component : NitroFixDBComponent,
    pathMatch : 'full'
  },
  {
    path:'fungenes',
    component: FungenesComponent,
    pathMatch : 'full'
  },
  {
    path:'links',
    component: LinksComponent,
    pathMatch : 'full'
  },
  {
    path: "software",
    component: SoftwareComponent,
    pathMatch : 'full'
  },
  {
    path: "people",
    component: PeopleComponent,
    pathMatch : 'full'
  },
  {
    path: "location",
    component: LocationComponent,
    pathMatch : 'full'
  },
  {
    path: "guide",
    component: GuideComponent,
    pathMatch : 'full'
  },
  {
    path: "NFixDB",
    component: NitroFixDBComponent,
    pathMatch : 'full'
  },
  {
    path: "structures",
    component: FungenesComponent,
    pathMatch : 'full'
  },
  {
    path:'**',
    component: P404Component
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
